<template>
  <multiselect
    placeholder="All"
    selectLabel
    :show-labels="false"
    v-model="selected"
    @input="updateFilters"
    :options="options"
  >
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'SingleSelectFilter',
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  components: {
    Multiselect,
  },
  data: () => ({
    selected: [],
  }),
  methods: {
    updateFilters(selection) {
      this.$emit('updateFilters', selection)
    },
  },
}
</script>
