<template>
  <div class="mt-3 mb-2">
    <b-skeleton v-if="isSearching" animation="fade" width="90px" height="22px" class="mt-3 mb-2" />
    <template v-else>
      <animated-number
        :value="count"
        :formatValue="parseInt"
        :duration="500"
        class="font-weight-bold survey-count"
      />
      <span class="survey-text">
        <span v-if="count !== totalSurveyCount"> of {{ totalSurveyCount }}</span>
        total surveys
      </span>
    </template>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import AnimatedNumber from 'animated-number-vue'
const SurveyModule = createNamespacedHelpers('survey')

export default {
  name: 'SurveyCount',
  props: {
    isSearching: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    AnimatedNumber,
  },
  computed: {
    ...SurveyModule.mapState(['count', 'totalSurveyCount']),
  },
}
</script>

<style scoped>
.survey-count {
  color: #6e84a3;
}
.survey-text {
  color: #95aac9;
}
</style>
