<template>
  <div>
    <div v-if="initialized">
      <SurveyOffstate v-if="surveys.length === 0 && Object.keys(listFilters).length === 0" />
      <div v-else>
        <div class="card">
          <SurveyTableFilters @resetSkip="resetSkip" @queryPending="setQueryPending" />
          <div v-if="!queryPending">
            <SurveyListTable v-if="count" />
            <div v-if="!count" class="text-center my-3">
              No results, try adjusting your filters.
            </div>
          </div>
          <div v-if="queryPending">
            <b-skeleton-table :rows="8" :columns="7"></b-skeleton-table>
          </div>
        </div>
        <div class="text-center" v-if="count > skip + limit && !queryPending">
          <button @click="loadMore" class="btn btn btn-sm btn-outline-primary">
            Load more surveys
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <b-skeleton height="300px"/>
      <b-skeleton height="60px" v-for="x in 5" :key="x" />
    </div>
  </div>
</template>

<script>
import SurveyListTable from '@/components/Modules/Survey/SurveyListTable'
import SurveyTableFilters from '@/components/Modules/Survey/SurveyTableFilters'
import SurveyOffstate from '@/components/Modules/Survey/SurveyOffstate'
import FullLoader from '@/components/MainContent/FullLoader'
import { createNamespacedHelpers } from 'vuex'

const surveyModule = createNamespacedHelpers('survey')
const locationModule = createNamespacedHelpers('location')

// @group Surveys
// Surveys page - This card holds the table filters and survey table.
export default {
  name: 'SurveyTableCard',
  components: {
    SurveyTableFilters,
    SurveyListTable,
    SurveyOffstate,
    FullLoader,
  },
  data: () => ({
    skip: 0,
    limit: 50,
    queryPending: false,
    initialized: false,
  }),
  computed: {
    ...surveyModule.mapState(['surveys', 'listFilters', 'count']),
  },
  async mounted() {
    await this.getUserLocationsByActiveCompany()
    await this.fetchMultipleCompanySources()
    this.initialized = true
  },
  methods: {
    ...surveyModule.mapActions(['getSurveys', 'fetchMultipleCompanySources']),
    ...surveyModule.mapMutations(['setIsSearching', 'resetListFilters']),
    ...locationModule.mapActions(['getUserLocationsByActiveCompany']),

    // @vuese
    // Fetches more surveys and appends to current list
    async loadMore() {
      this.skip += this.limit
      await this.getSurveys({ skip: this.skip, limit: this.limit })
    },
    setQueryPending(isPending) {
      this.queryPending = isPending
    },
    resetSkip() {
      this.skip = 0
    },
  },
}
</script>
