<template>
  <b-sidebar
    id="winback-sidebar"
    bg-variant="white"
    right
    no-header
    width="500px"
    class="border-left bd-left-gray"
    @hidden="reset"
    backdrop
    style="z-index: 1000"
  >
    <div v-if="selectActiveConversation && !showLoader" class="h-100">
      <MessageList :showClose="true" :isSidebar="true" v-if="selectActiveConversation._id && !showLocationSelector" />

      <!-- case for no conversations -->
      <div
        v-if="!selectActiveConversation._id"
        class="h-100 d-flex align-items-center justify-content-center"
      >
        <div class="text-center p-4">
          <h3>This Customer doesn't have an existing conversation yet.</h3>
          <h4 class="mb-4">Please select a location to create a conversation with:</h4>
          <SingleLocationFilter
            :locations="selectActiveLocations"
            @updateLocation="selectConversationLocation"
            placeholder="Select Location"
          />
        </div>
      </div>

      <!-- case for multiple locations -->
      <div
        v-if="selectActiveConversation._id && showLocationSelector"
        class="h-100 d-flex align-items-center justify-content-center"
      >
        <div class="text-center p-4">
          <h3>This Customer has multiple locations associated with them.</h3>
          <h4 class="mb-4">Please select a location to converse through:</h4>
          <SingleLocationFilter
            :locations="selectActiveLocations"
            @updateLocation="setConversationByLocation"
            placeholder="Select Location"
          />
        </div>
      </div>
    </div>

    <!-- loader -->
    <div
      class="d-flex align-items-center justify-content-center h-100"
      v-if="showLoader || !selectActiveConversation"
    >
      <b-spinner variant="primary" />
    </div>
  </b-sidebar>
</template>

<script>
import MessageList from '@/components/Modules/Winback/SubViews/MessageList'
import SingleLocationFilter from '@/components/Modules/CustomQuestions/QuestionCreation/Filters/SingleLocationFilter'
import { createNamespacedHelpers } from 'vuex'

const ConversationModuleV2 = createNamespacedHelpers('conversationV2')
const WebsocketModuleV2 = createNamespacedHelpers('websocketV2')
const LocationModule = createNamespacedHelpers('location')
const CustomerModule = createNamespacedHelpers('customer')
const UserModule = createNamespacedHelpers('user')

export default {
  name: 'WinbackSidebar',
  components: {
    MessageList,
    SingleLocationFilter,
  },
  data: () => ({
    showLoader: false,
  }),
  async mounted() {
    this.updateFilters({})
    if (this.selectConnectionReady) {
      await this.winbackCompanyChannelSubscribe()
    }
  },
  beforeDestroy() {
    this.winbackCompanyChannelUnsubscribe()
  },
  watch: {
    selectConnectionReady: {
      async handler(val, oldVal) {
        if (val && !oldVal) {
          this.winbackCompanyChannelSubscribe()
        }
      },
    },
  },
  computed: {
    ...ConversationModuleV2.mapGetters(['selectActiveConversation', 'selectDrawerLocationId']),
    ...WebsocketModuleV2.mapGetters(['selectConnectionReady']),
    ...LocationModule.mapGetters(['selectActiveLocations']),
    ...CustomerModule.mapGetters(['selectCustomerById']),
    ...UserModule.mapGetters(['onlineUser']),
    customerLocations() {
      return this.selectActiveConversation
        ? this.selectCustomerById(this.selectActiveConversation.customer?._id)?.locations
        : []
    },
    showLocationSelector() {
      const userActiveLocationsIds = this.selectActiveLocations?.map((l) => l._id)
      const customerActiveCompanyLocations = this.customerLocations?.filter((l) =>
        userActiveLocationsIds.includes(l)
      )
      return customerActiveCompanyLocations?.length > 1 && !this.selectDrawerLocationId
        ? true
        : false
    },
    locationOptions() {
      return this.onlineUser.role === 'admin'
        ? this.selectActiveLocations
        : this.selectActiveLocations.filter((al) => this.onlineUser.locations.includes(al._id))
    },
  },
  methods: {
    ...ConversationModuleV2.mapActions([
      'updateFilters',
      'createConversation',
      'setSpecificConversation',
      'updateDrawerLocationId',
      'resetAllConversationData',
    ]),
    ...WebsocketModuleV2.mapActions([
      'winbackCompanyChannelSubscribe',
      'winbackCompanyChannelUnsubscribe',
    ]),
    async reset() {
      await this.resetAllConversationData()
      await this.updateFilters({})
    },
    async selectConversationLocation(location) {
      this.showLoader = true
      this.updateDrawerLocationId(location?._id)
      await this.createConversation({
        locationId: location._id,
        customerId: this.selectActiveConversation.customer._id,
        companyId: this.selectActiveConversation.company._id,
      })
      this.showLoader = false
    },
    async setConversationByLocation(location) {
      this.showLoader = true

      // check if current active location matches selected location
      // if not the same location then fetch and set the right conversation via 'setSpecificConversation'
      if (this.selectActiveConversation.location?._id !== location._id) {
        await this.setSpecificConversation({
          filters: {
            locationId: location._id,
            customer: this.selectActiveConversation.customer,
            companyId: this.selectActiveConversation.company,
          },
          trace: 'WinbackSidebar',
        })
      }
      this.updateDrawerLocationId(location?._id)
      this.showLoader = false
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .b-sidebar {
  border-left: 1.5px solid #d2ddec;
}
</style>
