<template>
  <div class="row align-items-center">
    <div class="col-auto pr-0">
      <span class="fas fa-spinner fa-spin text-muted" v-if="isSearching"></span>
      <span class="fe fe-search text-muted" v-else></span>
    </div>
    <div class="col">
      <input
        type="search"
        class="form-control form-control-flush search"
        placeholder="Search"
        v-model="search"
        @input="onTextChanged"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash-es'

export default {
  name: 'CustomerStringFilter',
  props: ['isSearching'],
  data: () => ({
    search: '',
  }),
  watch: {
    search: debounce(function () {
      let formattedSearch
      if (this.search.match(/^[\d \-()]+$/g)) {
        formattedSearch = this.search.replace(/\D/g, '')
      }
      this.$emit('updateSearch', formattedSearch || this.search)
    }, 1000),
  },
  methods: {
    onTextChanged(value) {
      this.$emit('onTextChanged', this.search)
    },
  },
}
</script>

<style scoped lang="scss"></style>
