<template>
  <multiselect
    placeholder="Type to search"
    selectLabel
    :close-on-select="false"
    v-model="selectedSources"
    :multiple="true"
    @input="updateSources"
    :searchable="true"
    :showLabels="false"
    :limitText="(count) => `+${count} more`"
    :limit="showLimit"
    :options="sources"
  >
    <span slot="noResult">No results.</span>
    <template slot="placeholder">
      <span class="text-secondary"> <span class="fas fa-inbox"></span> All Sources </span>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { createNamespacedHelpers } from 'vuex'

const surveyModule = createNamespacedHelpers('survey')
const companyModule = createNamespacedHelpers('company')

export default {
  name: 'SourceFilter',
  components: {
    Multiselect,
  },
  props: {
    showLimit: {
      type: Number,
      default: 2,
    },
    multiCompany: {
      type: Boolean,
      default: false,
    },
    forInsights: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedSources: [],
  }),
  computed: {
    ...surveyModule.mapState(['sources']),
    ...companyModule.mapState(['activeCompany']),
  },
  methods: {
    ...surveyModule.mapActions(['getSources', 'fetchMultipleCompanySources']),
    reset(locations) {
      this.filteredLocations = []
    },
    updateSources(sources) {
      this.$emit('updateSources', sources)
    },
    sourceName({ name }) {
      return name
    },
  },
  watch: {
    activeCompany: {
      immediate: true,
      async handler() {
        ;(await this.multiCompany)
          ? this.forInsights
            ? this.fetchMultipleCompanySources({ insightsOnly: true })
            : this.fetchMultipleCompanySources()
          : this.getSources()
      },
    },
  },
}
</script>
