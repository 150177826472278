<template>
  <MainContent>
    <SurveyView v-if="activeCompany" :key="activeCompany._id"/>
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import SurveyView from '@/components/Modules/Survey/SurveyView'
import { trackEvent } from '@/lib/analytics'
import { createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'SurveysPage',
  components: {
    MainContent,
    SurveyView,
  },
  mounted() {
    trackEvent(this.$intercom, 'Surveys')
  },
  computed: {
    ...CompanyModule.mapState(['activeCompany']),
  },
}
</script>
