<template>
  <div>
    <CustomerTimelineModalGroup />
    <TimelineModal
      @resolve="resolveConversation($event._id)"
      @unresolve="unresolveConversation($event._id)"
      @block="blockCustomer({ companyId: $event.company, customerId: $event.customer._id })"
      @unblock="unblockCustomer({ companyId: $event.company, customerId: $event.customer._id })"
    />
    <div class="container-fluid" v-if="initialized">
      <SurveyHeader />
      <SurveyTableCard />
    </div>
  </div>
</template>

<script>
import SurveyHeader from '@/components/Modules/Survey/SurveyHeader'
import SurveyView from '@/components/Modules/Survey/SurveyView'
import SurveyTableCard from '@/components/Modules/Survey/SurveyTableCard'
import CustomerTimelineModalGroup from '@/components/Modules/Customer/CustomerTimeline/CustomerTimelineModalGroup'
import TimelineModal from '@/components/Modules/WinbackV2/TimelineModal'
import { createNamespacedHelpers } from 'vuex'

const WinbackModule = createNamespacedHelpers('winback')
const GroupModule = createNamespacedHelpers('group')
const DataCoreModule = createNamespacedHelpers('dataCore')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'SurveyView',
  components: {
    SurveyHeader,
    CustomerTimelineModalGroup,
    SurveyTableCard,
    TimelineModal,
  },
  data: () => ({
    initialized: false,
  }),
  async mounted() {
    await this.fetchGroups({
      memberType: 'location',
      companyIds:
        this.activeCompany._id === 'ALL_COMPANIES'
          ? this.companies.map((c) => c._id)
          : [this.activeCompany._id],
    })
    this.initialized = true
  },
  computed: {
    ...CompanyModule.mapGetters(['activeCompany', 'companies']),
    ...DataCoreModule.mapGetters(['selectActiveCompanyIds']),
  },
  methods: {
    ...GroupModule.mapActions(['fetchGroups']),
    ...WinbackModule.mapActions([
      'blockCustomer',
      'unblockCustomer',
      'resolveConversation',
      'unresolveConversation',
    ]),
  },
}
</script>
