<template>
  <div class="card col-12">
    <div class="card-body text-center">
      <div class="row">
        <div class="col-12">
          <img
            src="/img/illustrations/happiness.svg"
            alt="..."
            class="img-fluid mt--5 mb-4"
            style="max-width: 280px"
          />

          <!-- <div> -->
          <h2>No feedback yet.</h2>

          <p class="text-muted">
            See what customers are telling you so you know what to focus on next.
          </p>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SurveyOffstate',
}
</script>

<style scoped lang="scss"></style>
