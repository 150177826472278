<template>
  <multiselect
    :style="{ width: ratings && ratings.length ? 'auto' : '105px' }"
    placeholder="Type to search"
    selectLabel
    :hideSelected="false"
    :close-on-select="false"
    v-model="ratings"
    :multiple="true"
    @input="updateRatings"
    :searchable="false"
    :showLabels="false"
    :limitText="(count) => `+${count} more`"
    :limit="2"
    :options="surveyRatings"
    :custom-label="ratingLabel"
    track-by="score"
  >
    <template slot="placeholder">
      <span class="text-secondary"> <i class="far fa-smile"></i> All ratings </span>
    </template>
    <template slot="option" slot-scope="props">
      <div>
        <span class="text-capitalize">{{ props.option.emoti }} {{ props.option.title }}</span>
      </div>
    </template>
  </multiselect>
</template>

<script>
import { getRating, surveyRatings } from '@/lib/rating'
import Multiselect from 'vue-multiselect'
import { createNamespacedHelpers } from 'vuex'
const CustomerModule = createNamespacedHelpers('customer')

export default {
  name: 'CustomerRatingFilter',
  components: {
    Multiselect,
  },
  data: () => ({
    surveyRatings: surveyRatings,
    ratings: [],
  }),
  computed: {
    ...CustomerModule.mapState(['listFilters']),
  },
  methods: {
    updateRatings(ratings) {
      this.$emit('updateRatings', ratings)
    },
    ratingLabel({ emoti, title }) {
      return `${emoti} ${title}`
    },
  },
  mounted() {
    if (this.listFilters && this.listFilters.surveyRatings) {
      this.ratings = this.listFilters.surveyRatings.map((rating) => getRating(rating))
    }
  },
}
</script>

<style lang="scss">
.multiselect__tag span {
  text-transform: capitalize;
}
</style>
