<template>
  <div class="header">
    <div class="header-body">
      <div class="row align-items-center">
        <div class="col">
          <h1 class="header-title">Surveys</h1>
        </div>
        <div class="col-auto">
          <button class="btn btn-outline-primary" @click="verifyExport()">
            <span class="fe fe-download" v-if="!pendingExport"></span>
            <span class="fas fa-spinner fa-spin" v-else></span>
            Export
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex'
const SurveyModule = createNamespacedHelpers('survey')

// @group Survey
// This Component is the Header on the 'Feedback' page
export default {
  name: 'SurveyHeader',
  data: () => ({
    pendingExport: false,
  }),
  methods: {
    ...SurveyModule.mapActions(['generateSurveysCSV']),

    // @vuese
    // Displays modal verifying the user wants to export with the current filters.
    async verifyExport() {
      const message = `
        A CSV file will be created with the filters 
        that are currently applied to the Surveys table.
        `
      const h = this.$createElement
      const titleVNode = h('div', [
        h('h2', { class: ['mb-0'], domProps: { innerHTML: 'Confirm Export' } }),
      ])
      const exportConfirmed = await this.$bvModal.msgBoxConfirm(message, {
        title: [titleVNode],
        size: 'md',
        okTitle: 'Export',
        cancelTitle: 'Cancel',
        centered: true,
      })
      if (exportConfirmed) this.exportSurveysCSV()
    },

    // @vuese
    // Downloads the exported CSV filtered by the selected date range
    async exportSurveysCSV() {
      try {
        this.$notify({
          duration: 3000,
          title: `Export Started`,
          text: `We'll notify you when it is ready for download.`,
        })

        this.pendingExport = true
        const response = await this.generateSurveysCSV()
        this.pendingExport = false

        let fileURL = window.URL.createObjectURL(new Blob([response.data.csv]))
        let fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', `Surveys.csv`)
        document.body.appendChild(fileLink)
        fileLink.click()

        this.$notify({
          duration: 3000,
          text: `Successfully Downloaded Export`,
        })
      } catch (error) {
        this.pendingExport = false
        this.$notify({
          type: 'error',
          duration: 5000,
          title: `Exporting surveys failed!`,
          text: `Please refresh the page and try again.`,
        })
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
